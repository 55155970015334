import React from 'react';
import '../sass/header.scss';

const Header = () => (
    <header className="header">
        <h1 className="header__h1">
            Pot <span className="header__icon">o'</span> Goals!
        </h1>
    </header>
);

export default Header;