export function formatPrice(cents) {
    if (cents < 1) return "$0.00";
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }
  
  export function rando(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }
  
  export function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }
  
  export function getFunName() {
    const adjectives = [
      "adorable",
      "beautiful",
      "clean",
      "drab",
      "elegant",
      "fancy",
      "glamorous",
      "handsome",
      "long",
      "magnificent",
      "old-fashioned",
      "plain",
      "quaint",
      "sparkling",
      "ugliest",
      "unsightly",
      "angry",
      "bewildered",
      "clumsy",
      "defeated",
      "embarrassed",
      "fierce",
      "grumpy",
      "helpless",
      "itchy",
      "jealous",
      "lazy",
      "mysterious",
      "nervous",
      "obnoxious",
      "panicky",
      "repulsive",
      "scary",
      "thoughtless",
      "uptight",
      "worried"
    ];
  
    const nouns = [
      "women",
      "men",
      "children",
      "teeth",
      "feet",
      "people",
      "leaves",
      "mice",
      "geese",
      "halves",
      "knives",
      "wives",
      "lives",
      "elves",
      "loaves",
      "potatoes",
      "tomatoes",
      "cacti",
      "foci",
      "fungi",
      "nuclei",
      "syllabuses",
      "analyses",
      "diagnoses",
      "oases",
      "theses",
      "crises",
      "phenomena",
      "criteria",
      "data"
    ];
  
    return `${rando(adjectives)}-${rando(adjectives)}-${rando(nouns)}`;
  }

  export function randomColor() {
    const colors = [
      "lightsalmon",
      "salmon",
      "darksalmon",
      "lightcoral",
      "indianred",
      "crimson",
      "firebrick",
      "red",
      "darkred",
      "coral",
      "tomato",
      "orangered",
      "gold",
      "orange",
      "darkorange",
      "lightyellow",
      "lemonchiffon",
      "lightgoldenrodyellow",
      "papayawhip",
      "moccasin",
      "peachpuff",
      "palegoldenrod",
      "khaki",
      "darkkhaki",
      "yellow",
      "lawngreen",
      "chartreuse",
      "limegreen",
      "lime",
      "forestgreen",
      "green",
      "darkgreen",
      "greenyellow",
      "yellowgreen",
      "springgreen",
      "mediumspringgreen",
      "lightgreen",
      "palegreen",
      "darkseagreen",
      "mediumseagreen",
      "seagreen",
      "olive",
      "darkolivegreen",
      "olivedrab",
      "lightcyan",
      "cyan",
      "aqua",
      "aquamarine",
      "mediumaquamarine",
      "paleturquoise",
      "turquoise",
      "mediumturquoise",
      "darkturquoise",
      "lightseagreen",
      "cadetblue",
      "darkcyan",
      "teal",
      "powderblue",
      "lightblue",
      "lightskyblue",
      "skyblue",
      "deepskyblue",
      "lightsteelblue",
      "dodgerblue",
      "cornflowerblue",
      "steelblue",
      "royalblue",
      "blue",
      "mediumblue",
      "darkblue",
      "navy",
      "midnightblue",
      "mediumslateblue",
      "slateblue",
      "darkslateblue",
      "lavender",
      "thistle",
      "plum",
      "violet",
      "orchid",
      "fuchsia",
      "magenta",
      "mediumorchid",
      "mediumpurple",
      "blueviolet",
      "darkviolet",
      "darkorchid",
      "darkmagenta",
      "purple",
      "indigo",
      "pink",
      "lightpink",
      "hotpink",
      "deeppink",
      "palevioletred",
      "mediumvioletred",
      "gainsboro",
      "lightgray",
      "silver",
      "darkgray",
      "gray",
      "dimgray",
      "lightslategray",
      "slategray",
      "darkslategray",
      "black",
      "cornsilk",
      "blanchedalmond",
      "bisque",
      "navajowhite",
      "wheat",
      "burlywood",
      "tan",
      "rosybrown",
      "sandybrown",
      "goldenrod",
      "peru",
      "chocolate",
      "saddlebrown",
      "sienna",
      "brown",
      "maroon"
    ];
    return colors[Math.floor(Math.random() * (colors.length + 1))];
  }